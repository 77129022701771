import { IconProps } from '@/libs/types'
import React from 'react'

const DocumentWithCheckIcon: React.FC<IconProps> = ({
  height = 'h-6',
  width = 'w-6',
  additionalClasses = '',
  color = 'currentColor',
  bgColor = 'bg-transparent',
}) => {
  return (
    <svg
      className={`${height} ${width} ${additionalClasses} ${color} ${bgColor}`}
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00217 6.19998H10.4972L10.5 6.19999H14.5558C16.7898 6.20321 18.5998 8.01522 18.5998 10.25V21.5C20.5881 21.5 22.1998 19.8882 22.1998 17.9V6.32984C22.1998 4.52337 20.8501 2.95591 19.003 2.8026C18.7345 2.78031 18.4654 2.75984 18.1959 2.74119C17.596 1.58776 16.39 0.799988 15 0.799988H13.2C11.81 0.799988 10.604 1.58775 10.0041 2.74117C9.73446 2.75982 9.46533 2.7803 9.1967 2.8026C7.39396 2.95222 6.06503 4.44882 6.00217 6.19998ZM13.2 2.59999C12.2059 2.59999 11.4 3.40588 11.4 4.39999H16.8C16.8 3.40588 15.9941 2.59999 15 2.59999H13.2Z"
        fill="#56A97F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599976 10.25C0.599976 9.00735 1.60734 7.99999 2.84998 7.99999H14.55C15.7926 7.99999 16.8 9.00735 16.8 10.25V23.75C16.8 24.9926 15.7926 26 14.55 26H2.84998C1.60734 26 0.599976 24.9926 0.599976 23.75V10.25ZM12.1028 15.7622C12.4133 15.3741 12.3503 14.8077 11.9622 14.4972C11.5741 14.1867 11.0077 14.2496 10.6972 14.6378L7.72535 18.3526L6.63637 17.2636C6.2849 16.9121 5.71505 16.9121 5.36358 17.2636C5.01211 17.6151 5.01211 18.1849 5.36358 18.5364L7.16358 20.3364C7.3446 20.5174 7.59414 20.6128 7.84975 20.5986C8.10535 20.5845 8.34284 20.4621 8.50276 20.2622L12.1028 15.7622Z"
        fill="#56A97F"
      />
    </svg>
  )
}

export default DocumentWithCheckIcon
