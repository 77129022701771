import React from 'react'
import { IconProps } from '@/libs/types'

const FormIcon: React.FC<IconProps> = ({
  height = 'h-6',
  width = 'w-6',
  additionalClasses = '',
  color = 'currentColor',
  bgColor = 'bg-transparent',
}) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${height} ${width} ${additionalClasses}`}
    >
      <circle cx="12" cy="12" r="12" fill={bgColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800049 2.8C0.800049 1.47452 1.87457 0.400002 3.20005 0.400002H8.70299C9.33951 0.400002 9.94996 0.652858 10.4 1.10295L14.4971 5.2C14.9472 5.65009 15.2 6.26054 15.2 6.89706V17.2C15.2 18.5255 14.1255 19.6 12.8 19.6H3.20005C1.87456 19.6 0.800049 18.5255 0.800049 17.2V2.8ZM3.20005 10C3.20005 9.33726 3.73731 8.8 4.40005 8.8H11.6C12.2628 8.8 12.8 9.33726 12.8 10C12.8 10.6627 12.2628 11.2 11.6 11.2H4.40005C3.73731 11.2 3.20005 10.6627 3.20005 10ZM4.40005 13.6C3.73731 13.6 3.20005 14.1373 3.20005 14.8C3.20005 15.4627 3.73731 16 4.40005 16H11.6C12.2628 16 12.8 15.4627 12.8 14.8C12.8 14.1373 12.2628 13.6 11.6 13.6H4.40005Z"
        fill={color}
      />
    </svg>
  )
}

export default FormIcon
